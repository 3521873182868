.segunda-seccion {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    min-height: 719px;
    padding-top: 50px;
    background-image: url(../../assets/img/home/secciones/segunda/fondo.png);
    background-size: cover;
}

.contenedor-textos{
    z-index: 20;
}

.marca-agua {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 220px;
    pointer-events: none;
    z-index: 0;
}

.texto {
    position: relative;
    color: #fff;
    max-width: 758.144px;
    padding-left: 30px;
    font-size: 25px;
    line-height: 35px;
    margin-top: 30px;
    z-index: 20;
}

.img{
    position: relative;
    z-index: 10;
    width: 610px;
    max-width: 100%;
}

@media (min-width: 601px) and (max-width: 1200px){
    .img {
        margin: 0 0 120px !important;
    }
}

@media (min-width: 601px) and (max-width: 920px){
    .img{
        position: relative;
        z-index: 10;
        width: 610px;
        max-width: 100%;
    }
}

@media (max-width: 600px){
    .texto{
        font-size: 16px;
        line-height: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .segunda-seccion{
        min-height: auto;
    }
}