.contenedor{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    min-height: 635px;
    background-color: transparent;
    padding-top: 90px;
}

.contenedor-texto-subrayado{
    position: relative;
    z-index: 20;
}

.contendor-titulo{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 30px;
}

@media (min-width: 600px) and (max-width: 1200px) {
    .contenedor-tamanios {
        margin: 15px 0!important;
    }
    .contenedor-titulos {
        margin: 20px 0!important;
    }
    }
.contenedor-imagen {
    text-align: center;
}

.contenedor-especificaciones{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 24px;
}

.img{
    position: relative;
    z-index: 20;
    width: 536px;
    max-width: 100%;
    margin-top: 87.5px;
}

.icono{
    width: 60px;
}

.contenedor-especificacion{
    position: relative;
    z-index: 20;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
}

.btn{
    padding: 10px 20px;
    color: #fff;
    background-color: #0080c1;
    text-decoration: none;
    border-radius: 1rem;
    font-size: 16px;
    font-family: 'Helvetica';
    transition: background-color .3s ease;
}

.btn:hover{
    background-color: #FA7513;
}

.btn-descarga{
    display: inline-block;
    text-align: center;
    width: 135px;
    height: 40px;
    margin-left: 38px;
    padding: 2px 20px;
    line-height: 18px;
}

.h3{
    position: relative;
    z-index: 20;
    font-size: 40px;
    color: #0080c1;
    margin: 0px;
}

.contenedor-botones{
    position: relative;
    z-index: 20;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.span{
    font-size: 22px;
    color: #1d3d62;
    font-weight: bold;
}

.p{
    position: relative;
    z-index: 20;
    max-width: 100%;
    margin: 0px;
    font-size: 22px;
    color: #1d3d62;
}

.titulo-especificacion{
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #1d3d62;
    margin-bottom: 0px;
}

.descripcion-especificacion{
    text-align: center;
    font-size: 16px;
    color: #1d3d62;
    margin: 0px;
}

.gris{
    background-color: #efefef;
}

.contenedor-derecho{
    padding-top: 113px;
}

@media (max-width: 600px) {
    .btn-descarga{
        margin-left: 10px;
    }
    .btn, .btn-descarga{
        padding: 5px 10px;
        width: auto;
        height: auto;
    }
    .contenedor-botones{
        justify-content: flex-start;
        padding: 10px 0px;
    }
    .img{
        margin-top: 20px;
    }
    .p, .span{
        font-size: 18px;
    }
    .titulo-especificacion, .descripcion-especificacion{
        font-size: 14px;
    }
    .icono{
        width: 50px;
    }
    .h3{
        font-size: 30px;
    }
    .rango{
        margin-top: 15px;
    }
}