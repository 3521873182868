.a{
    background-color: #0080c1;
    padding: 10px 30px;
    color: #fff;
    text-decoration: none;
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    margin-bottom: 20px;
    font-family: 'Helvetica';
}

@media (max-width:600px) {
    .a{
        font-size: 14px;
    }
}