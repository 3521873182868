.header{
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 100;
    padding: 30px;
}

.ul{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    list-style: none;
}

.link{
    text-decoration: none;
    color: #fff;
    font-family: 'Bebas';
    margin-right: 20px;
    font-size: 24px;
}

.contenedor-boton{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.link-menu{
    text-decoration: none;
    color: #1d3d62;
    font-family: 'Bebas';
    margin-right: 20px;
    font-size: 26px;
}

.boton-idioma{
    border-radius: 1rem;
    background-color: rgb(0, 128, 193);
    color: #fff;
    border: none;
    padding: 8px 16px;
    margin-right: 15px;
}

@media (max-width: 600px) {
    .header{
        padding: 0px;
    }
    .boton-idioma{
        font-size: 12px;
        padding: 6px 12px;
    }
}