.segunda-seccion{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    min-height: 719px;
    padding-top: 105px;
    padding-bottom: 205px;
    background-image: url(../../../../assets/img/home/secciones/segunda/fondo.png);
    background-size: cover;
}

.marca-agua{
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.texto{
    position: relative;
    color: #fff;
    max-width: 558.144px;
    padding-left: 30px;
    font-size: 20px;
    line-height: 30px;
    margin-top: 50px;
    z-index: 10;
}

@media (max-width: 600px){
    .segunda-seccion{
        padding-top: 20px;
        padding-bottom: 0px;
        height: auto;
        min-height: auto;
    }
    .texto{
        font-size: 14px;
        line-height: normal;
        margin-top: 20px;
    }
}