.contenedor{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    min-height: 635px;
    background-color: transparent;
    padding-top: 90px;
}

.contenedor-texto-subrayado{
    position: relative;
    z-index: 20;
} 

.contenedor-imagen {
    text-align: center;
}


@media (min-width: 600px) and (max-width: 1200px) {
.contenedor-titulo {
    margin: 40px 0!important; 
}
}

.img{
    position: relative;
    z-index: 20;
    width: 536px;
    max-width: 100%;
    margin-top: 50px;
}

.btn{
    display: block;
    padding: 10px 20px;
    color: #fff;
    background-color: #0080c1;
    text-decoration: none;
    border-radius: 1rem;
    font-size: 17px;
    text-align: center;
    font-size: 16px;
    font-family: 'Helvetica';
    transition: background-color .3s ease;
}

.btn:hover{
    background-color: #FA7513;
}

.h3{
    position: relative;
    z-index: 20;
    font-size: 40px;
    color: #0080c1;
    margin: 0px;
}

.contenedor-botones{
    position: relative;
    z-index: 20;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.p{
    position: relative;
    z-index: 20;
    max-width: 100%;
    margin: 0px;
    font-size: 22px;
    color: #1d3d62;
}

.gris{
    background-color: #efefef;
}

.contenedor-derecho{
    padding-top: 0px;
}

.contendor-titulo{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 30px;
}

@media (max-width: 600px) {
    .btn-descarga{
        margin-left: 10px;
    }
    .btn, .btn-descarga{
        padding: 5px 10px;
        display: inline-block;
    }
    .contenedor-botones{
        justify-content: flex-end;
    }
    .img{
        margin-top: 20px;
    }
    .p, .span{
        font-size: 18px;
    }
    .titulo-especificacion, .descripcion-especificacion{
        font-size: 14px;
    }
    .icono{
        width: 50px;
    }
    .h3{
        font-size: 34px;
    }
}