.contenedor{
    position: relative;
}

.marca{
    position: absolute;
    z-index: 0;
    top: 0px;
    right: 0px;
    height: 100%;
    pointer-events: none;
}