.contenedor {
    position: relative;
    min-height: 783px;
    background-image: url(../../../../assets/img/home/secciones/sexta/fondo.png);
}

.contenido {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.contenedor-texto {
    max-width: 438px;
}

.contenedor-cuadros {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    max-width: 867px;
}

.marca-agua {
    position: absolute;
    top: 0px;
    width: 100%;
}

.cuadro {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    align-items: flex-end;
    width: 372px;
    height: 287px;
    /*padding: 50px;*/
    max-width: 100%;

    margin-bottom: 39px;
    z-index: 10;
    border-top: none;
}

.h3 {
    position: relative;
    z-index: 10;
    font-size: 2rem;
    color: #fff;
    padding-left: 45px;
    border-left: 3px solid #eea660;
    font-size: 52px;
    width: 569px;
}

@media (max-width: 1300px){
    .h3 br {
        display: none;
    }
}

.p {
    color: #fff;
    padding-left: 45px;
    font-size: 25px;
}

.p-cuadro {
    color: #fff;
    padding: 50px;
    margin: 0;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;

}

.hr {
    width: 70%;
    border-bottom: 2px solid #fff;
    margin: 0px;
    margin-left: 0px;
}

.icono {
    position: absolute;
    top: 25px;
    left: 10px;
    width: 90px;
}

@media (max-width: 615px) {
    .h3 {
        display: none!important;
    }
} 

@media (max-width: 600px) {
    .h3 {
        font-size: 30px;
        margin: 10px;
        padding-left: 10px;
    }

    .p {
        font-size: 16px;
        padding-left: 20px;
    }

    .cuadro {
        margin-bottom: 10px;
        height: 200px;
        padding: 0px 20px;
    }

    .p-cuadro {
        padding: 20px;
    }

    .icono {
        height: 60px;
        width: 60px;
        top: 30px;
        left: 45px;
    }

    .contenedor-cuadros{
        justify-content: center;
        max-width: 100%;
    }
}