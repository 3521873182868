.contenedor{
    min-height: 887px;
    background-color: #efefef;
}

@media (max-width: 600px) {
    .contenedor{
        min-height: 10px;
        height: auto;
    }
}