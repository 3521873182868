.lista{
    padding: 0px;
    list-style: none;
    z-index: 10;
}

.opcion{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 15px;
}

.opcion-seleccionada{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #0080C1;
    cursor: pointer;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 15px;
}

.flecha{
    width: 18px;
    margin-right: 12px;
    opacity: 0;
}

.flecha-seleccionada{
    width: 18px;
    margin-right: 12px;
    opacity: 1;
}

.img{
    width: 525px;
    height: 435px;
    object-fit: cover;
    max-width: 100%;
}

@media (max-width: 1440px){
    .opcion, .opcion-seleccionada{
        font-size: 22px;
    }
}
@media (min-width: 601px) and (max-width: 1000px){
    .li{
        width: 33%;
    }
    .lista{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }
    .opcion, .opcion-seleccionada{
        justify-content: center;
    }
}

@media (max-width: 600px){
    .lista{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }
    .opcion, .opcion-seleccionada{
        font-size: 16px;
        text-align: center;
    }

    .li{
        width: 33%;
    }

    .flecha-seleccionada, .flecha{
        margin-right: 4px;
    }
}