@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/HelveticaNeue.ttc'); 
}

@font-face {
    font-family: 'Bebas';
    src: url('./assets/fonts/BebasNeue-Regular.ttf'); 
}


* {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Helvetica';
}

p{
    font-family: 'Helvetica';
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Bebas';
}

input, textarea{
    font-family: 'Helvetica';
}

.app{
    position: relative;
}

.naranja{
    color: #eea660;
}

/*************************/
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev.swiper-button-disabled,
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    color: #fff;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: #fff;
    width: 20px;
    height: 20px;
}

.MuiGrid-item{
    padding-top: 0px !important;
}

.seccion-slider-clientes .swiper-button-next:after,
.seccion-slider-clientes .swiper-rtl .swiper-button-prev:after,
.seccion-slider-clientes .swiper-button-prev.swiper-button-disabled,
.seccion-slider-clientes .swiper-button-prev,
.seccion-slider-clientes .swiper-rtl .swiper-button-next
{
    color: #eea660;
}