.contenedor{
    position: relative;
    /*height: 900px;*/
    height: 85vh;
}

.img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.logo{
    position: absolute;
    z-index: 100;
    top: 68px;
    left: 75px;
    width: 350px;
    max-width: 100%;
    cursor: pointer;
}

.sombra{
    position: absolute;
    top: 0px;
    width: 100%;
}

@media (max-width: 600px){
    .contenedor{
        height: 600px;
    }

    .logo{
        width: 150px;
        top: 30px;
        left: 10px;
    }
}