.contenedor{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    height: 1041px;
    background-color: #eeeeee;
}

.marca{
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.contenedor-inputs{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
}

.form{
    position: relative;
    z-index: 10;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}

.h3{
    color: #1d3d62;
    font-size: 36px;
    font-weight: 100;
}

.input{
    width: 100%;
    border-radius: 20px;
    color: #fff;
    background-color: #1d3d62;
    border: none;
    outline: none;
    padding: 10px 44px;
    margin-bottom: 37px;
}

.input::placeholder, .text-area::placeholder{
    color: #fff;
}

.text-area{
    width: 100%;
    height: 350px;
    border-radius: 20px;
    color: #fff;
    background-color: #1d3d62;
    border: none;
    outline: none;
    padding: 10px 44px;
    margin-bottom: 37px;
    resize: none;
}

.volver{
    position: relative;
    z-index: 10;
    width: 220px;
    text-align: center;
    text-decoration: none;
    background-color: #0080c1;
    color: #fff;
    padding: 10px 55px;
    border-radius: 20px;
}

@media (max-width: 600px) {
    .contenedor{
        height: auto;
    }
    .form{
        padding: 0px 20px;
    }
    .h3{
        font-size: 18px;
        text-align: center;
    }

    .input, .text-area{
        font-size: 14px;
        padding: 10px 20px;
    }

    .text-area{
        height: 100px;
    }

    .volver{
        margin-bottom: 20px;
        width: auto;
    }
}