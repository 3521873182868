.contenedor-slider{
    position: relative;
}

.contenedor-texto-slider{
    position: absolute;
    color: #fff;
    z-index: 20;
    top: 60%;
    left: 38px;
}

.h3{
    border-bottom: 3px solid #fff;
    padding-bottom: 15px;
    max-width: 120px;
    font-size: 28px;
}

.h3-hover{ 
    margin: 0px;
    max-width: 120px;
    font-size: 36px;
    color: #fff;
    border-bottom: 3px solid #fff;
    cursor: pointer;
}

.p{
    font-family: 'Bebas';
    font-size: 15px;
    margin: 15px 0px 15px 0px;
}

.flecha{
    width: 18px;
}

.link-url{
    text-decoration: none;
}

.img{
    max-height: 700px;
    width: 100%;
    object-fit: cover;
}

@media (max-width: 600px) {
    .img{
        width: 100%;
        max-width: 100%;
        max-height: 500px;
        object-fit: cover;
    }
    .contenedor-texto-slider{
        top: auto;
        bottom: 30px;
        left: 0px;
        width: 100%;
    }
    .h3,.h3-hover{
        font-size: 20px;
        width: 100%;
        max-width: 100%;
        color: #fff;
        padding-left: 20px;
        border: none;
        text-shadow: 0px 0px 3px #1d3d62;
        border-left: 2px solid #FA7513;
        padding-bottom: 0px;
    }
    .p{
        padding-left: 20px;
        color: #fff;
        text-shadow: 0px 0px 3px #1d3d62;
        font-size: 16px;
        margin: 5px 0px;
    }
}