#inicio{
    position: relative;
}

.contenedor-slide{
    position: relative;
    width: 100%;
    height: 85vh;
    background-size: cover;
}

.sombra{
    position: absolute;
    width: 100%;
    max-height: 100%;
}

.contenedor-texto{
    position: absolute;
    bottom: 10%;
    width: 65.34%;
    height: 29.16%;
    padding: 38px 78px 38px 304px;
    color: #fff;
    background-size: cover;
}


.contenedor-flecha{
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #fff;
}

.expandir{
    width: 100%;
    filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(195deg) brightness(110%) contrast(104%);
}

@media (min-width: 900px) and (max-width: 1250px){
    .contenedor-texto{
        width: 90.34%;
        height: 39.16%;
        padding: 38px 78px 38px 104px;
    }
}

@media (min-width: 700px) and (max-width: 899px){
    .contenedor-texto{
        width: 95.34%;
        height: 35.16%;
        padding: 38px 78px 38px 94px;
    }
}

@media (min-width: 600px) and (max-width: 699px){
    .contenedor-texto{
        width: 95.34%;
        padding: 38px 78px 38px 94px;
        height: 45.16%;
    }
}

.h3{
    text-transform: uppercase;
    font-size: 48px;
    margin: 0px;
    border-left: 5px solid #FA7513;
    padding-left: 15px;
    margin-bottom: 20px;
}

.p{
    font-size: 20px;
    margin: 0px;
    padding-left: 15px;
}

.logo{
    position: absolute;
    top: 68px;
    left: 75px;
    z-index: 100;
    width: 350px;
    max-width: 100%;
    cursor: pointer;
}

@media (max-width: 1670px){
    .h3{
        font-size: 32px;
    }
}

@media (max-width: 600px){
    .contenedor-flecha{
        width: 30px;
        height: 30px;
        border-width: 2px;
    }
    .contenedor-slide{
        height: 600px;
    }
    .h3{
        font-size: 18px;
        margin-bottom: 8px;
    }
    .contenedor-texto{
        position: absolute;
        bottom: 10%;
        width: 100%;
        height: 29.16%;
        padding: 30px;
        color: #fff;
    }
    .p{
        font-size: 14px;
        margin: 0px;
        padding-left: 15px;
    }

    .logo{
        width: 150px;
        top: 30px;
        left: 10px;
    }
}