.contenedor{
    margin-top: 70px;
}

.contenedor-steps{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.contenedor-step{
    width: 220px;
}

.contenedor-bullet-linea{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.contenedor-bullet{
    width: 40px;
    height: 40px;
}

.bullet-active{
    width: 40px;
    cursor: pointer;
    height: 40px;
    background-color: #0080c1;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.bullet{
    width: 40px;
    cursor: pointer;
    height: 40px;
    background-color: #bcbcbc;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.linea{
    background-color: #bcbcbc;
    height: 10px;
    width: 100%;
}

.linea-oculta{
    background-color: #efefef;
    height: 10px;
    width: 100%;
}

.p{
    text-align: center;
    font-family: 'Bebas';
    color: #1d3d62;
    font-size: 30px;
    margin: 10px 0px;
}

.contenedor-datos{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px;
}

.contenedor-texto{
    width: 680px;
    max-width: 100%;
}

.img{
    /*width: 576px;*/
    width: 506px;
    max-width: 100%;
}

.h3{
    color: #0080c1;
    font-size: 36px;
}

.descripcion{
    color: #1d3d62;
    font-size: 26px;
}

@media (max-width: 600px) {
    .contenedor{
        margin-top: 20px;
    }
    .p{
        font-size: 12px;
        font-weight: bold;
    }
    .contenedor-bullet{
        width: 20px;
        height: 20px;
    }
    .bullet, .bullet-active{
        width: 20px;
        height: 20px;
    }
    .linea-oculta, .linea{
        height: 5px;
    }
    .contenedor-step{
        max-width: 20%;
    }

    .contenedor-datos{
        margin-top: 10px;
    }

    .h3{
        font-size: 18px;
        padding: 0px 20px;
    }

    .descripcion{
        padding: 0px 20px;
        font-size: 14px;
        text-align: justify;
    }
}