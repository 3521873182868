.contenedor-titulo{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-left: 30px;
    border-left: 3px solid #FA7513;
}

.titulo{
    color: #fff;
    font-size: 48px;
    margin: 0px;
}

.pleca-horizontal{
    margin-right: 20px;
}

@media (max-width: 600px) {
    .titulo{
        font-size: 24px;
    }
    .contenedor-titulo{
        padding-left: 10px;
        margin-left: 20px;
    }
}