.contenedor{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
}

.contenedor-slider{
    width: 75%;
}

.contenedor-imagen{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    height: 310px;
}

.img{
    width: 100%;
}

.h3{
    color: #1d3d62;
    font-size: 55px;
}

@media (max-width: 600px){
    .h3{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .contenedor-slider{
        width: 100%;
    }
    .contenedor-imagen{
        height: auto;
    }
}