.h3{
    padding-top: 84px;
    color: #fff;
    font-family: 'Heveltica bold';
    font-size: 32px;
}

.p{
    color: #fff;
    font-size: 18px;
}

.a{
    color: #fff;
}

.logo{
    width: 100%;
    padding-top: 100px;
}

.derechos{
    padding-top: 100px;
}

.padding{
    padding-top: 150px;
}

.icono{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.texto-redes{
    padding-top: 45px;
}

.p-whatsapp{
    display: flex;
    align-items: center;
}

.link{
    text-decoration: none;
    transition: all 300ms ease;
    cursor: pointer;
}

.link:hover{
    text-decoration: underline;
}

@media (max-width: 600px) {
    .h3{
        padding-top: 20px;
        font-size: 20px;
    }
    .derechos{
        padding: 0px;
    }
    .logo{
        padding: 20px 0px;
    }

    .p{
        font-size: 14px;
    }
    .padding{
        padding-top: 50px;
    }
    .icono{
        width: 20px;
        height: 20px;
    }
}